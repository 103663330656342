<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-card class="px-2 mx-2" elevation="0">
      <v-data-table
        :headers="headers"
        :items="dataTable"
        :items-per-page="100"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="mt-4 px-4 pt-4 pb-10">
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="#36AC87"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
            <v-col class="text-right" cols="12" sm="7">
              <v-btn
                v-if="isAllowModify"
                color="#2E976C"
                class="text-capitalize"
                style="color: white"
                @click="dialog = true"
              >
                Tambah Universitas
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.city`]="{ item }">
          {{ item.city || "-" }}
        </template>

        <template v-slot:[`item.phone`]="{ item }">
          {{ item.phone || "-" }}
        </template>

        <template v-slot:[`item.email`]="{ item }">
          {{ item.email || "-" }}
        </template>

        <template v-slot:[`item.website`]="{ item }">
          {{ item.website || "-" }}
        </template>

        <template v-slot:[`item.member_count`]="{ item }">
          {{ item.member_count || "-" }}
        </template>

        <template v-slot:[`item.status_id`]="{ item }">
          <template>
            <v-icon :color="item.status_id === 1 ? '#32A179' : '#CCCCCC'">
              mdi-check-circle
            </v-icon>
          </template>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <div
              class="action-style"
              @click="toDelete(item)"
              v-if="isAllowModify"
            >
              <v-icon class="action-icon" color="#e21927"> mdi-delete </v-icon>
            </div>
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#2E976C"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic">
              {{ itemCount + " dari " + itemTotal + " ditampilkan" }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <modal-tambah :show="dialog" @close="closeDialog" @init="initialize" />

    <modal-konfirmasi :show="modalDelete" @submit="confirmDelete" />
  </v-container>
</template>

<script>
import axios from "axios";
import ModalTambah from "./ModalTambah.vue";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalTambah,
    ModalKonfirmasi,
  },

  data: () => ({
    dialog: false,
    dataDetail: null,

    search: "",
    headers: [
      { text: "Universitas", align: "start", value: "name", sortable: false },
      { text: "Lokasi", value: "city", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Website", value: "website", sortable: false },
      { text: "Anggota", value: "member_count", sortable: false },
      { text: "Status", value: "status_id" },
      { text: "", value: "actions" },
    ],
    dataTable: [],
    page: 1,
    pageCount: 0,
    itemCount: 0,
    itemTotal: 0,
    isAllowModify: false,

    selectedItem: null,
    modalDelete: false,
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize(this.page);
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    toDetail(p) {
      // console.log(p)
      this.getDetail(p.mc_hash);
      this.dialog = true;
    },

    onChangePage(e) {
      this.page = e;
      this.initialize(e);
    },

    filterData() {
      this.initialize(this.page);
    },

    filterClear() {
      this.initialize(this.page);
    },

    initialize(page) {
      const userInfo = JSON.parse(localStorage.getItem("userinfo"));
      const userMenus = userInfo.menu_function;
      if (userMenus.includes("UNIVERSITY_MOD")) this.isAllowModify = true;

      const requestBody = {
        // type: type,
        page: page,
        search: this.search,
      };

      axios
        .post("/v1/admin/university", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.dataTable = res.data.data.university.list;
            this.pageCount = res.data.data.university.pagination.last_page;
            this.itemCount = res.data.data.university.pagination.per_page;
            this.itemTotal = res.data.data.university.pagination.total;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getDetail(p) {
      const requestBody = {
        mc_hash: p,
      };

      axios
        .post("/v1/admin/company/detail", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data)
            this.dataDetail = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    toDelete(p) {
      this.selectedItem = p;
      this.modalDelete = true;
    },

    confirmDelete(p) {
      if (p === 0) {
        this.modalDelete = false;
      } else {
        this.submitDelete();
      }
    },

    submitDelete() {
      const requestBody = {
        univ_hash: this.selectedItem.univ_hash,
      };

      axios
        .post("/v1/admin/university/delete", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.modalDelete = false;
            this.$toast.success(res.data.message);
            this.initialize(this.page);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
