<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold"> Tambah Universitas </span>
            <span style="cursor: pointer" @click="close"> X </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Nama Universitas <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="namaUniversitas"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan nama universitas"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col class="txt16-gray50" cols="12" sm="4">
            Alamat <span class="symbol-required">*</span>
          </v-col>

          <v-col class="d-flex" cols="12" sm="8" style="flex-direction: column">
            <div>
              <v-custom-select
                v-model="selectedKabupatenKota"
                :options="kabupatenKota"
                :clearable="false"
                label="name_address"
                @search="getAddress"
                @option:selected="getKodePos"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <span class="mr-10"> Ketik minimal 3 huruf </span>
                </template>
              </v-custom-select>
            </div>

            <div class="txt16-gray50 mt-4">Kode Pos</div>

            <div class="mt-2">
              <v-custom-select
                v-model="selectedKodePos"
                :options="kodePos"
                :clearable="false"
              />
            </div>

            <div class="txt16-gray50 mt-4">Alamat Lengkap</div>

            <div class="mt-2">
              <v-textarea
                v-model="alamatLengkap"
                rows="4"
                no-resize
                outlined
                color="#36AC87"
                placeholder="Masukkan Alamat Lengkap"
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Nomer Telepon <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="nomerTelepon"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan nomer telepon"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            E-mail <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="email"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan email"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Website <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="website"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan website"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn
              color="#2E976C"
              class="mx-1 text-capitalize"
              style="font-weight: bold; color: white"
              @click="modal = true"
            >
              Simpan
            </v-btn>
            <v-btn
              color="#CCCCCC"
              class="mx-1 text-capitalize"
              style="font-weight: bold; color: white"
              @click="close"
            >
              Batal
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <modal-konfirmasi :show="modal" @submit="confirm" />
  </div>
</template>

<script>
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalKonfirmasi,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataModal: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    modal: false,
    namaUniversitas: "",
    selectedKabupatenKota: [
      {
        name_address: "Kabupaten / Kota / Kecamatan",
      },
    ],
    kabupatenKota: [],
    selectedKodePos: [
      {
        label: "Pilih Kode Pos",
      },
    ],
    kodePos: [],
    alamatLengkap: "",
    nomerTelepon: "",
    email: "",
    website: "",
  }),

  computed: {},

  watch: {},

  created() {},

  methods: {
    close() {
      this.$emit("close");
    },

    previewURL(p) {
      // console.log(p)
      const url = [];
      url.push(p.url);
      this.$viewerApi({ images: url });
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.submit();
      }
    },

    getAddress(e) {
      if (e.length < 2) return;

      const requestBody = {
        search: e,
      };

      axios
        .post("/v1/general/get-address", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.kabupatenKota = res.data.data.address.list;
            this.kabupatenKotaDarurat = res.data.data.address.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getKodePos(e) {
      // console.log(e)
      const requestBody = {
        hash_address: e.hash_address,
      };

      axios
        .post("/v1/general/get-postal-code", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.kodePos = res.data.data.postal_code.list;
            this.kodePosDarurat = res.data.data.postal_code.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    submit() {
      // console.log(e)
      const requestBody = {
        reg_university: this.namaUniversitas,
        reg_address_hash: this.selectedKabupatenKota.hash_address,
        reg_address_pos: this.selectedKodePos,
        reg_address: this.alamatLengkap,
        reg_phone: this.nomerTelepon,
        reg_email: this.email,
        reg_website_url: this.website,
      };

      axios
        .post("/v1/admin/university/create", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$emit("init");
            this.$toast.success(res.data.message);
            this.resetForm();
            this.close();
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    resetForm() {
      this.namaUniversitas = null;
      this.selectedKabupatenKota = [];
      this.selectedKodePos = [];
      this.alamatLengkap = null;
      this.nomerTelepon = null;
      this.email = null;
      this.website = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
