var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-sm-10 py-sm-10",staticStyle:{"min-height":"100vh","background":"#e6e6e6"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-2 mx-2",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"items-per-page":100,"mobile-breakpoint":"100","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-4 px-4 pt-4 pb-10"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari","color":"#36AC87","single-line":"","hide-details":"","clearable":"","outlined":"","dense":""},on:{"keyup":_vm.filterData,"click:append":_vm.filterData,"click:clear":_vm.filterClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"7"}},[(_vm.isAllowModify)?_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"color":"white"},attrs:{"color":"#2E976C"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Tambah Universitas ")]):_vm._e()],1)],1)]},proxy:true},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.city || "-")+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone || "-")+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email || "-")+" ")]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.website || "-")+" ")]}},{key:"item.member_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member_count || "-")+" ")]}},{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [[_c('v-icon',{attrs:{"color":item.status_id === 1 ? '#32A179' : '#CCCCCC'}},[_vm._v(" mdi-check-circle ")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.isAllowModify)?_c('div',{staticClass:"action-style",on:{"click":function($event){return _vm.toDelete(item)}}},[_c('v-icon',{staticClass:"action-icon",attrs:{"color":"#e21927"}},[_vm._v(" mdi-delete ")])],1):_vm._e()])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center my-5 py-3"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"#2E976C","total-visible":5},on:{"input":_vm.onChangePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('span',{staticStyle:{"font-size":"12px","font-style":"italic"}},[_vm._v(" "+_vm._s(_vm.itemCount + " dari " + _vm.itemTotal + " ditampilkan")+" ")])],1)]},proxy:true}],null,true)})],1),_c('modal-tambah',{attrs:{"show":_vm.dialog},on:{"close":_vm.closeDialog,"init":_vm.initialize}}),_c('modal-konfirmasi',{attrs:{"show":_vm.modalDelete},on:{"submit":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }